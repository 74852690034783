import React, { Component } from 'react';
import { Pivot, PivotItem, TextField, PrimaryButton, DefaultButton } from '@fluentui/react';
import { Editor } from '@tinymce/tinymce-react';

import styles from './Recipes.module.css';


class Settings extends Component {
    render() {
        const {settings, config, user} = this.props;

        if(Array.isArray(settings.references)){
            const length = settings.references?.length;

            if(length === 0 ){
                settings.references.push({key: length+1, text: '', prefix: '', sufix: ''});
            } else if (settings.references[length-1].text !== '' || settings.references[length-1].prefix !== '' || settings.references[length-1].sufix !== ''){
                settings.references.push({key: length+1, text: '', prefix: '', sufix: ''});
            }
        }

        return(
            <div className={styles.content}>
                <h1>Einstellungen</h1>

                {['system','admin'].indexOf(user.usergroup) === -1 &&
                <p><em>Sie haben nicht die nötigen Rechte, um Einstellungen zu ändern.</em></p>
                }

                {['system','admin'].indexOf(user.usergroup) !== -1 &&
                <>
                    <Pivot>
                        <PivotItem itemKey="general" headerText="Allgemein">
                            <h2>Impressum</h2>
                            {settings.imprint &&
                            <Editor
                                init={{height: 500, menubar: false}}
                                plugins={config.tinyMCE.plugins}
                                toolbar={config.tinyMCE.toolbar}
                                tinymceScriptSrc={config.tinyMCE.path}
                                value={settings.imprint}
                                onEditorChange={content => this.props.onSettingsChange('imprint', content)}
                            />
                            }
                        </PivotItem>

                        <PivotItem itemKey="privacy" headerText="Datenschutz">
                            <h2>Datenschutzerklärung</h2>
                            {settings.privacy &&
                            <Editor
                                init={{height: 500, menubar: false}}
                                plugins={config.tinyMCE.plugins}
                                toolbar={config.tinyMCE.toolbar}
                                tinymceScriptSrc={config.tinyMCE.path}
                                value={settings.privacy}
                                onEditorChange={content => this.props.onSettingsChange('privacy', content)}
                            />
                            }

                            {['system'].indexOf(user.usergroup) !== -1 &&
                            <>
                                <h2>Datenschutzerklärung (reduziert)</h2>
                                {settings.privacyReduced &&
                                <Editor
                                    init={{height: 500, menubar: false}}
                                    plugins={config.tinyMCE.plugins}
                                    toolbar={config.tinyMCE.toolbar}
                                    tinymceScriptSrc={config.tinyMCE.path}
                                    value={settings.privacyReduced}
                                    onEditorChange={content => this.props.onSettingsChange('privacyReduced', content)}
                                />
                                }
                            </>
                            }
                        </PivotItem>
                        {Array.isArray(settings.references) &&
                        <PivotItem itemKey="reference" headerText="Referenznummern">
                            <h2>Referenznummern</h2>
                            <table style={{width: '100%'}}>
                                <thead>
                                    <tr><td>#</td><td>Name</td><td>Prefix</td><td>Sufix</td></tr>
                                </thead>
                                <tbody>
                                {settings.references.map(item => {
                                    return <tr key={item.key}><td>{item.key}</td><td><TextField value={item.text} onChange={e => {this.changeRefs(item.key - 1, 'text', e.target.value)}} /></td><td><TextField value={item.prefix} onChange={e => {this.changeRefs(item.key - 1, 'prefix', e.target.value)}} /></td><td><TextField value={item.sufix} onChange={e => {this.changeRefs(item.key - 1, 'sufix', e.target.value)}} /></td></tr>
                                })}
                            </tbody>
                            </table>
                        </PivotItem>
                        }
                    </Pivot>


                    <div style={{marginTop: '1em'}}>
                        <PrimaryButton text="Speichern" onClick={() => this.props.onSave(settings)} style={{marginRight: '.7em'}} />
                        <DefaultButton text="Abbrechen" onClick={() => this.props.history.goBack()} />
                    </div>
                </>
                }
            </div>
        )
    }

    changeRefs(index, key, value){
        const copy = [...this.props.settings.references];

        copy[index][key] = value;

        this.props.onSettingsChange('references', copy)
    }
}


export default Settings;