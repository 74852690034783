import React, { Component } from 'react';
import { FontIcon, Dropdown, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { Link } from "react-router-dom";
import Config from './Config';

import styles from './Dashboard.module.css';


class Dashboard extends Component {
    constructor(props){
        super(props);

        this.config = Config;

        this.state = {
            location: 0,
            dialog: false,
        };
    }

    render() {
        const mode = this.props.user === 'filiale' ? 'filiale' : this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];

        return(
            <div className={styles.root}>
                {mode === 'users' &&
                <div className={styles.textbox}>
                    <h1>Ihre Zufriedenheit ist unser Ziel</h1>
                    <div className={styles.intro}>
                        <p>Mit Leidenschaft setzen wir uns jeden Tag aufs Neue für die höchste Qualität ein, die wir Ihnen bieten können. Das ist unser Anspruch. Aus diesem Grund hören wir auf Sie und haben dafür diese Plattform geschaffen. Wir möchten Sie um wenige Minuten Ihrer Zeit bitten, um an unseren Qualitätsbefragungen teilzunehmen. Denn nur mit Ihrer Meinung zu unseren Leistungen und unseren Produkten können wir uns nachhaltig verbessern. Wir danken Ihnen sehr für Ihre Teilnahme.</p>
                        <p>Diese Befragungen finden ebenfalls statt, um der Medizinprodukteverordnung (2017/745) zu entsprechen. Wir hoffen, mit dieser Plattform einen nützlichen und für Sie nachvollziehbaren sowie angenehmen Weg gefunden zu haben, den Inhalten dieser Verordnung nachzukommen. Über Anregungen zur Verbesserung der Verfahrensweise sind wir Ihnen jederzeit dankbar.</p>
                    </div>
                </div>
                }
                <div className={styles.nav}>
                    {priv.indexOf(mode) !== -1 &&
                    <Link className={styles.box} to="/users">
                        <FontIcon iconName="People" className={styles.icon} />
                        <span>Benutzer</span>
                    </Link>
                    }
                    {mode === 'users' &&
                    <Link className={styles.box} to={"/users/" + this.props.user.id}>
                        <FontIcon iconName="Contact" className={styles.icon} />
                        <span>Meine Daten</span>
                    </Link>
                    }
                    {mode !== 'filiale' &&
                    <>
                    <Link className={styles.box} to="/recipes">
                        <FontIcon iconName="Work" className={styles.icon} />
                        <span>Rezepte</span>
                    </Link>
                    <Link className={styles.box} to="/surveys">
                        <FontIcon iconName="AccountActivity" className={styles.icon} />
                        <span>Befragungen</span>
                    </Link>
                    </>
                    }
                    {priv.indexOf(mode) !== -1 &&
                    <>
                    <Link className={styles.box} to="/tickets">
                        <FontIcon iconName="Ticket" className={styles.icon} />
                        <span>Tickets ({this.props.ticketCount})</span>
                    </Link>
                    <Link className={styles.box} to="/statistics">
                        <FontIcon iconName="StackedLineChart" className={styles.icon} />
                        <span>Statistik</span>
                    </Link>
                    <span className={styles.box} onClick={() => {
                        if(this.props.user.location === 0){
                            this.setState({dialog: true});
                        } else {
                            this.props.onLocationModeClick();
                        }}}>
                        <FontIcon iconName="Play" className={styles.icon} />
                        <span>Filialmodus</span>
                    </span>
                    </>
                    }
                    {mode === 'filiale' &&
                    <>
                    <Link className={styles.box} to="/registration">
                        <FontIcon iconName="AddFriend" className={styles.icon} />
                        <span>Neuer Kunde</span>
                    </Link>
                    <span className={styles.box} onClick={e => this.props.onActivateLogin()}>
                        <FontIcon iconName="AuthenticatorApp" className={styles.icon} />
                        <span>Anmelden</span>
                    </span>
                    </>
                    }
                </div>

                <Dialog hidden={this.state.dialog === false} onDismiss={() => this.setState({dialog: false})} title="Filiale wählen">
                    <Dropdown
                        placeholder="Filiale wählen"
                        options={this.config.locationOptions}
                        selectedKey={this.state.location}
                        onChange={(e, item) => this.setState({location: item.key})}
                        className={styles.field}
                    />
                    <DialogFooter>
                      <PrimaryButton onClick={() => this.setState({dialog: false}, () => this.props.onLocationModeClick(this.state.location))} text="Filialmodus starten" disabled={this.state.location === 0} />
                    </DialogFooter>
                </Dialog>
            </div>
        )
    }
}

export default Dashboard;