import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PrimaryButton, SearchBox, Pivot, PivotItem, DetailsList, Selection, SelectionMode, Icon, Dropdown } from '@fluentui/react';

import styles from './Users.module.css';


class Users extends Component {
    constructor(props){
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                if(this._selection.getSelection().length > 0){
                    this.props.history.push('/users/' + this._selection.getSelection()[0].id);
                }
            }
        })

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: 0, text: 'Alle Filialen'});

        this.state = {
            search: typeof sessionStorage.mdrUsersSearch !== 'undefined' ? sessionStorage.mdrUsersSearch : '',
            pivot: typeof sessionStorage.mdrUsersPivot !== 'undefined' ? sessionStorage.mdrUsersPivot : 'all',
            location: typeof sessionStorage.mdrUsersLocation !== 'undefined' ? parseInt(sessionStorage.mdrUsersLocation) : this.props.user.location,
            sort:{field:'id', desc:false}
        }
    }
    onColumnHeaderClick=(e, column)=>{
        let feld = typeof(column.fieldName) !== 'undefined' ? column.fieldName : column.name;
        let desc = this.state.sort.field === feld ? !this.state.sort.desc : false;
        this.setState({sort:{field:feld, desc:desc}}, ()=>this.props.ListSorter.storeSort(this.constructor.name, this.state.sort));
    }

    componentDidMount(){
        this.props.onRefresh();
        this.refreshInterval = window.setInterval(() => this.props.onRefresh(), this.props.config.refreshInterval);
        if (typeof(this.props.ListSorter.getSort)==='function'){
            this.setState({sort: this.props.ListSorter.getSort(this.constructor.name,this.state.sort)})
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];

        let items = this.props.items;

        if(this.state.location !== 0){
            items = items.filter(item => item.location === this.state.location);
        }

        const countAll = items.length;
        const countAdmins = items.filter(item => item.usergroup === 'admin').length;
        const countEmployees = items.filter(item => item.usergroup === 'employee').length;
        const countUsers = items.filter(item => item.usergroup === 'users').length;

        if(this.state.pivot !== 'all'){
            items = items.filter(item => item.usergroup === this.state.pivot);
        }

        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');
            items = items.filter( item => {
                let found = true;
                parts.forEach(part => found &= (item.lastname.toLowerCase().indexOf(part) >= 0 || item.firstname.toLowerCase().indexOf(part) >= 0));
                return found;
            });
        }

        const columns = [
            {
                key: 'id',
                name: 'ID',
                fieldName: 'id',
                minWidth: 20,
                maxWidth: 30,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'title',
                name: 'Anrede',
                fieldName: 'title',
                minWidth: 30,
                maxWidth: 100,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'lastname',
                name: 'Name',
                fieldName: 'lastname',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'firstname',
                name: 'Vorname',
                fieldName: 'firstname',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'birthday',
                name: 'Geburtstag',
                minWidth: 50,
                maxWidth: 120,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    return item.birthday !== '' ? new Date(item.birthday).toLocaleDateString() : '';
                },
            },
            {
                key: 'location',
                name: 'Filiale',
                minWidth: 80,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.locationOptions.filter(location => location.key === item.location );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    if(item.location !== 0){
                        return item.location;
                    }
                },
            },
            {
                key: 'email',
                name: 'E-Mail',
                minWidth: 30,
                maxWidth: 120,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    return item.email !== '' ? <Icon iconName="CheckMark" className={styles.icon} /> : '';
                },
            },
            {
                key: 'phone',
                name: 'Telefon',
                minWidth: 30,
                maxWidth: 120,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    return (item.phone !== '' || item.mobile !== '') ? <Icon iconName="CheckMark" className={styles.icon} /> : '';
                },
            },
            {
                key: 'usergroup',
                name: 'Benutzergruppe',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.usergroupOptions.filter(usergroup => usergroup.key === item.usergroup );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    return item.usergroup;
                },
            },
        ].map(column => {
           return this.props.ListSorter.setColumn(column, this.state.sort)
        });
        items = this.props.ListSorter.sortItems(items, columns,this.state.sort.field , this.state.sort.desc?'desc':'asc');
        return(
            <div className={styles.root}>
                {priv.indexOf(usergroup) !== -1
                ?   <>
                    <div className={styles.heading}>
                        <PrimaryButton className={styles.left} text="Neuer Benutzer" onClick={(e) => this.props.history.push('/users/new')} />
                        <Dropdown
                            options={this.locationOptions}
                            selectedKey={this.state.location}
                            onChange={(e, item) => this.setState({location: item.key}, sessionStorage.setItem('mdrUsersLocation', item.key))}
                            className={styles.filterDropdown}
                        />
                        <SearchBox className={styles.search} placeholder="Suche" value={this.state.search} onChange={e => {if(e){this.setState({search: e.target.value}, sessionStorage.setItem('mdrUsersSearch', e.target.value))}}} onClear={() => this.setState({search: ''})} />
                    </div>

                    <div className={styles.content}>
                        <h1>Benutzerübersicht</h1>

                        <Pivot onLinkClick={(item) => this.setState({pivot: item.props.itemKey}, sessionStorage.setItem('mdrUsersPivot', item.props.itemKey))} selectedKey={this.state.pivot}>
                            <PivotItem itemKey="all" headerText="alle Benutzer" itemCount={countAll}></PivotItem>
                            <PivotItem itemKey="admin" headerText="Administratoren" itemCount={countAdmins}></PivotItem>
                            <PivotItem itemKey="employee" headerText="Mitarbeiter" itemCount={countEmployees}></PivotItem>
                            <PivotItem itemKey="users" headerText="Kunden" itemCount={countUsers}></PivotItem>
                        </Pivot>

                        <DetailsList
                            items={items}
                            compact={true}
                            columns={columns}
                            selectionMode={SelectionMode.single}
                            layoutMode={1}
                            selection={this._selection}
                            onColumnHeaderClick={this.onColumnHeaderClick}
                        />
                    </div>
                    </>
                :   <Redirect push to="/" />
                }
            </div>
        )
    }
}

export default Users;