import React, { Component } from 'react';
import { PrimaryButton, DefaultButton, SearchBox, Pivot, PivotItem, DetailsList, Selection, SelectionMode, Dropdown, Dialog, DialogFooter } from '@fluentui/react';

import styles from './Recipes.module.css';


class Recipes extends Component {
    constructor(props){
        super(props);

        this.priv = ['system', 'admin', 'employee'];

        this._selection = new Selection({
            onSelectionChanged: () => {
                if(this.priv.indexOf(this.props.user.usergroup) !== -1 && this._selection.getSelection().length > 0){
                    this.props.history.push('/recipes/' + this._selection.getSelection()[0].id);
                }
            }
        })

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: 0, text: 'Alle Filialen'});

        this.productOptions = [...this.props.config.productOptions];
        this.productOptions.unshift({key: 0, text: 'Alle Produkte'});

        this.state = {
            search: typeof sessionStorage.mdrRecipesSearch !== 'undefined' ? sessionStorage.mdrRecipesSearch : '',
            pivot: typeof sessionStorage.mdrRecipesPivot !== 'undefined' ? sessionStorage.mdrRecipesPivot : 'all',
            location: typeof sessionStorage.mdrRecipesLocation !== 'undefined' ? parseInt(sessionStorage.mdrRecipesLocation) : this.props.user.location,
            productType: typeof sessionStorage.mdrRecipesProductType !== 'undefined' ? parseInt(sessionStorage.mdrRecipesProductType) : 0,
            deactivateItem: null,
            sort:{field:'id', desc:false}
        }
    }
    onColumnHeaderClick=(e, column)=>{
        let feld = typeof(column.fieldName) !== 'undefined' ? column.fieldName : column.name;
        let desc = this.state.sort.field === feld ? !this.state.sort.desc : false;
        this.setState({sort:{field:feld, desc:desc}}, ()=>this.props.ListSorter.storeSort(this.constructor.name, this.state.sort));
    }


    componentDidMount(){
        this.props.onRefresh();
        this.refreshInterval = window.setInterval(() => this.props.onRefresh(), this.props.config.refreshInterval);
        if (typeof(this.props.ListSorter.getSort)==='function'){
            this.setState({sort: this.props.ListSorter.getSort(this.constructor.name,this.state.sort)})
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    render() {
        const usergroup = this.props.user.usergroup;

        let items = this.props.items;

        if(this.state.location !== 0){
            items = items.filter(item => item.location === this.state.location);
        }

        if(this.state.productType !== 0){
            items = items.filter(item => item.product === this.state.productType);
        }

        const countAll = items.length;
        const countNotTaken = items.filter(item => item.deliveryDate === '').length;
        const countTaken = items.filter(item => item.deliveryDate !== '').length;

        if(this.state.pivot === 'taken'){
            items = items.filter(item => item.deliveryDate !== '');
        }

        if(this.state.pivot === 'notTaken'){
            items = items.filter(item => item.deliveryDate === '');
        }

        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');

            const filteredUsers = this.props.users.filter( item => {
                let found = true;
                parts.forEach(part => found &= (item.lastname.toLowerCase().indexOf(part) >= 0 || item.firstname.toLowerCase().indexOf(part) >= 0));
                return found;
            }).map( user => user.id);

            const filteredRecipes = items.filter( item => {
                let found = true;
                parts.forEach(part => found &= item.operationID.toLowerCase().indexOf(part) >= 0 );
                return found;
            }).map( recipe => recipe.id);

            items = items.filter( item => filteredRecipes.indexOf(item.id) >= 0 || filteredUsers.indexOf(item.user) >= 0);
        }

        let columns = [
            {
                key: 'column0',
                name: 'MPG',
                fieldName: 'operationID',
                minWidth: 30,
                maxWidth: 40,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'column1',
                name: 'Kunde',
                fieldName: 'user',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (this.props.users){
                        const copy = [...this.props.users];
                        const result = copy.filter(user => user.id === item.user);
                        if(result.length > 0){
                            return result[0].firstname + ' ' + result[0].lastname;
                        }
                    }
                    return item.user;
                },
            },
            {
                key: 'column2',
                name: 'Filiale',
                fieldName: 'location',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.locationOptions.filter(location => location.key === item.location );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    return item.location;
                },
            },
            {
                key: 'column3',
                name: 'Produkt',
                fieldName: 'product',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.productOptions.filter(product => product.key === item.product );
                    if(result.length >0 ){
                        return result[0].text;
                    }
                    return item.product;
                },
            },
            {
                key: 'column4',
                name: 'Auftragsdatum',
                fieldName: 'orderDate',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const orderDate = item.orderDate !== '' ? new Date(item.orderDate).toLocaleDateString() : '';
                    return orderDate;
                },
            },
            {
                key: 'column5',
                name: 'Abholdatum',
                fieldName: 'deliveryDate',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const deliveryDate = item.deliveryDate !== '' ? new Date(item.deliveryDate).toLocaleDateString() : '';
                    return deliveryDate;
                },
            },
        ].map(column => {
            return this.props.ListSorter.setColumn(column, this.state.sort)
         });
         items = this.props.ListSorter.sortItems(items, columns,this.state.sort.field , this.state.sort.desc?'desc':'asc');;

        return(
            <div className={this.priv.indexOf(usergroup) !== -1 ? styles.root : styles.rootUsers}>
                {this.priv.indexOf(usergroup) !== -1 && 
                <>
                    <div className={styles.heading}>
                        <PrimaryButton className={styles.left} text="Neues Rezept" onClick={(e) => this.props.history.push('/recipes/new')} />
                        <Dropdown
                            options={this.productOptions}
                            selectedKey={this.state.productType}
                            onChange={(e, item) => this.setState({productType: item.key}, sessionStorage.setItem('mdrRecipesProductType', item.key))}
                            className={styles.filterDropdown}
                        />
                        <Dropdown
                            options={this.locationOptions}
                            selectedKey={this.state.location}
                            onChange={(e, item) => this.setState({location: item.key}, sessionStorage.setItem('mdrRecipesLocation', item.key))}
                            className={styles.filterDropdown}
                        />
                        <SearchBox className={styles.search} placeholder="Suche" value={this.state.search} onChange={e => {if(e){this.setState({search: e.target.value}, sessionStorage.setItem('mdrRecipesSearch', e.target.value))}}} onClear={() => this.setState({search: ''})} />
                    </div>

                    <div className={styles.content}>
                        <h1>Rezeptübersicht</h1>

                        <Pivot onLinkClick={(item) => this.setState({pivot: item.props.itemKey}, sessionStorage.setItem('mdrRecipesPivot', item.props.itemKey))} selectedKey={this.state.pivot}>
                            <PivotItem itemKey="all" headerText="alle Rezepte" itemCount={countAll}></PivotItem>
                            <PivotItem itemKey="taken" headerText="abgeholte Rezepte" itemCount={countTaken}></PivotItem>
                            <PivotItem itemKey="notTaken" headerText="nicht abgeholte Rezepte" itemCount={countNotTaken}></PivotItem>
                        </Pivot>

                        <DetailsList
                            items={items}
                            compact={true}
                            columns={columns}
                            selectionMode={this.priv.indexOf(usergroup) !== -1 ? SelectionMode.single : SelectionMode.none}
                            layoutMode={1}
                            selection={this._selection}
                            onColumnHeaderClick={this.onColumnHeaderClick}
                        />
                    </div>
                </>
                }

                {this.props.user.usergroup === 'users' &&
                <>
                    <div className={styles.heading}>
                        <h1>Meine Rezepte</h1>
                    </div>

                    <div className="recipes">
                        {items.map(item => {
                            const result = this.props.config.productOptions.filter(product => product.key === item.product );

                            return (
                            <div className={styles.recipe} key={item.id}>
                                <h2>{item.operationID} {result[0].text}</h2>
                                <p>Bestelldatum: {item.orderDate}</p>
                                {item.deliveryDate !== null && <p>Abholdatum: {item.deliveryDate}</p>}
                                {item.contactAllowed === false && <DefaultButton text='Benachrichtigungen aktivieren' onClick={() => this.activateItem(item.id)} />}
                                {item.contactAllowed === true && <DefaultButton text='Benachrichtigungen deaktivieren' onClick={() => this.setState({deactivateItem: item.id})} />}
                            </div>
                            )
                        })}
                        {items.length === 0 &&
                        <p><em>Es liegen noch keine Rezepte vor.</em></p>
                        }
                    </div>
                    <DefaultButton text="Zurück zur Übersicht" onClick={() => this.props.history.goBack()} />
                    <Dialog
                        hidden={this.state.deactivateItem === null}
                        onDismiss={() => this.setState({deactivateItem: null})}
                        title="Benachrichtigungen deaktivieren"
                    >
                        <p>Wollen Sie sich wirklich von unserer Qualitätssicherung abmelden?</p>
                        <DialogFooter>
                            <PrimaryButton onClick={() => this.setState({deactivateItem: null})} text="Nein" />
                            <DefaultButton onClick={() => this.deactivateItem()} text="Ja" />
                        </DialogFooter>
                    </Dialog>
                </>
                }
            </div>
        )
    }

    activateItem(id){
        const temp = this.props.items.filter( item => item.id === id);
        if(temp.length > 0){
            temp[0].contactAllowed = true;
            this.props.onSave(temp[0]);
        }
    }

    deactivateItem(){
        const temp = this.props.items.filter( item => item.id === this.state.deactivateItem);
        if(temp.length > 0){
            temp[0].contactAllowed = false;
            this.props.onSave(temp[0]);
        }
        this.setState({deactivateItem: null});
    }
}

export default Recipes;