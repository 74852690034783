import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontIcon, PrimaryButton, SearchBox, Pivot, PivotItem, DetailsList, Selection, SelectionMode, Dropdown } from '@fluentui/react';

import styles from './Tickets.module.css';


class Tickets extends Component {
    constructor(props){
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                if(this._selection.getSelection().length > 0){
                    this.props.history.push('/tickets/' + this._selection.getSelection()[0].id);
                }
            }
        })

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: 0, text: 'Alle Filialen'});

        this.employeeOptions = [{key: 0, text: 'Alle Mitarbeiter'}];

        this.state = {
            search: typeof sessionStorage.mdrTicketsSearch !== 'undefined' ? sessionStorage.mdrTicketsSearch : '',
            pivot: typeof sessionStorage.mdrTicketsPivot !== 'undefined' ? sessionStorage.mdrTicketsPivot : 'all',
            location: typeof sessionStorage.mdrTicketsLocation !== 'undefined' ? parseInt(sessionStorage.mdrTicketsLocation) : this.props.user.location,
            employee: typeof sessionStorage.mdrTicketsEmployee !=='undefined' ? parseInt(sessionStorage.mdrTicketsEmployee) : this.props.user.id,
            sort:{field:'id', desc:false}
        }
    }

    onColumnHeaderClick=(e, column)=>{
        let feld = typeof(column.fieldName) !== 'undefined' ? column.fieldName : column.name;
        let desc = this.state.sort.field === feld ? !this.state.sort.desc : false;
        this.setState({sort:{field:feld, desc:desc}}, ()=>this.props.ListSorter.storeSort(this.constructor.name, this.state.sort));
    }

    componentDidMount(){
        this.props.onRefresh();
        this.refreshInterval = window.setInterval(() => this.props.onRefresh(), this.props.config.refreshInterval);
        if (typeof(this.props.ListSorter.getSort)==='function'){
            this.setState({sort: this.props.ListSorter.getSort(this.constructor.name,this.state.sort)})
        }

        this.employeeOptions = [{key: 0, text: 'Alle Mitarbeiter'}];
        this.props.users.forEach(item => {
            if(item.usergroup !== 'users'){
                this.employeeOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
            }
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.users !== prevProps.users){
            this.employeeOptions = [{key: '', text: 'Alle Mitarbeiter'}];
            this.props.users.forEach(item => {
                if(item.usergroup !== 'users'){
                    this.employeeOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];

        let items = this.props.items;

        if(this.state.location !== 0){
            items = items.filter(item => item.location === this.state.location);
        }

        if(this.state.employee !== 0){
            items = items.filter(item => item.history[item.history.length-1].employee === this.state.employee || item.history[item.history.length-1].employee === 1);
        }

        const countAll = items.length;
        const countOpen = items.filter(item => item.status === 'open').length;
        const countClosed = items.filter(item => item.status === 'closed').length;

        if(this.state.pivot !== 'all'){
            items = items.filter(item => item.status === this.state.pivot);
        }

        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');
            items = items.filter( item => {
                let found = true;
                parts.forEach(part => found &= (item.subject.toLowerCase().indexOf(part) >= 0));
                return found;
            });
        }

        const columns = [
            {
                key: 'column0',
                name: 'ID',
                fieldName: 'id',
                isResizable: true,
                isPadded: true,
                minWidth: 10,
                maxWidth: 20,
            },
            {
                key: 'column1',
                name: 'Kunde',
                fieldName: 'customer',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (this.props.users){
                        const copy = [...this.props.users];
                        const result = copy.filter(user => user.id === item.customer);
                        if(result.length > 0){
                            return result[0].lastname + ', ' + result[0].firstname;
                        }
                    }
                    return item.customer;
                },
            },
            {
                key: 'column2',
                name: 'Filiale',
                fieldName: 'location',
                minWidth: 120,
                maxWidth: 160,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.locationOptions.filter(location => location.key === item.location );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    return item.location;
                },
            },
            {
                key: 'column3',
                name: 'Betreff',
                fieldName: 'subject',
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Mitarbeiter',
                fieldName: 'employee',
                minWidth: 200,
                maxWidth: 300,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (this.props.users){
                        const result = this.props.users.filter(user => user.id === item.history[item.history.length-1].employee);
                        if(result.length > 0){
                            return result[0].firstname + ' ' + result[0].lastname;
                        }
                    }
                },
            },
            {
                key: 'column5',
                name: 'Status',
                fieldName: 'status',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const result = this.props.config.ticketStatusOptions.filter(status => status.key === item.status );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    return item.status;
                },
            },
            {
                key: 'column6',
                name: ' ',
                fieldName: 'check',
                minWidth: 15,
                maxWidth: 15,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if(item.status === 'closed'){
                        return <FontIcon iconName="SkypeCircleCheck" className={styles.icon} />;
                    } else {
                        return '';
                    }
                }
            },
        ].map(column => {
            return this.props.ListSorter.setColumn(column, this.state.sort)
         });
         items = this.props.ListSorter.sortItems(items, columns,this.state.sort.field , this.state.sort.desc?'desc':'asc');

        return(
            <div className={styles.root}>
                {priv.indexOf(usergroup) !== -1
                ?   <>
                    <div className={styles.heading}>
                        <PrimaryButton className={styles.left} text="Neues Ticket" onClick={(e) => this.props.history.push('/tickets/new')} />
                        <Dropdown
                            options={this.employeeOptions}
                            selectedKey={this.state.employee}
                            onChange={(e, item) => this.setState({employee: item.key}, sessionStorage.setItem('mdrTicketsEmployee', item.key))}
                            className={styles.filterDropdown}
                        />
                        <Dropdown
                            options={this.locationOptions}
                            selectedKey={this.state.location}
                            onChange={(e, item) => this.setState({location: item.key}, sessionStorage.setItem('mdrTicketsLocation', item.key))}
                            className={styles.filterDropdown}
                        />
                        <SearchBox className={styles.search} placeholder="Suche" value={this.state.search} onChange={e => {if(e){this.setState({search: e.target.value}, sessionStorage.setItem('mdrTicketsSearch', e.target.value))}}} onClear={() => this.setState({search: ''})} />
                    </div>

                    <div className={styles.content}>
                        <h1>Ticketübersicht</h1>

                        <Pivot onLinkClick={(item) => this.setState({pivot: item.props.itemKey}, sessionStorage.setItem('mdrTicketsPivot', item.props.itemKey))} selectedKey={this.state.pivot}>
                            <PivotItem itemKey="all" headerText="alle Tickets" itemCount={countAll}></PivotItem>
                            <PivotItem itemKey="open" headerText="offene Tickets" itemCount={countOpen}></PivotItem>
                            <PivotItem itemKey="closed" headerText="geschlossene Tickets" itemCount={countClosed}></PivotItem>
                        </Pivot>

                        <DetailsList
                            items={items}
                            compact={true}
                            columns={columns}
                            layoutMode={1}
                            selectionMode={SelectionMode.single}
                            selection={this._selection}
                            onColumnHeaderClick={this.onColumnHeaderClick}
                        />
                    </div>
                    </>
                :   <Redirect push to="/" />
                }
            </div>
        )
    }
}

export default Tickets;