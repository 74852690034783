import React, { Component } from 'react';

import styles from './Page.module.css';


class Page extends Component {
    render() {
        return(
            <div className={styles.root} dangerouslySetInnerHTML={this.props.content}>
            </div>
        )
    }
}

export default Page;