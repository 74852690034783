import React, { Component } from 'react';
import { TextField, ChoiceGroup, Label, Checkbox, PrimaryButton, DefaultButton } from '@fluentui/react';

import styles from './Survey.module.css';

const _ = {};
_.merge = require('lodash/merge');


class Survey extends Component {
    state = {
        answers: {},
        questionaer: {}
    };

    componentDidMount(){
        this._selectItem();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.id !== prevProps.match.params.id || this.props.items !== prevProps.items){
            this._selectItem();
        }
    }

    render() {
        const readOnly = this.state.item?.filled !== '';
        let result = [];

        if(this.state.questionaer.questions){
            result = this.state.questionaer.questions.filter(item => this.checkConditions(item));
        }

        return(
            <div className={styles.root + ' survey-root'}>
                <div className={styles.content}>
                {result.length > 0 && 
                <>
                <h1>Qualitätsbefragung <br /><small>{this.state.questionaer.text}</small></h1>

                {this.state.questionaer.intro !== '' && 
                    <p><em>{this.state.questionaer.intro}</em></p>
                }

                <form className={styles.form}>
                    <div className={styles.questions}>
                    {result.map((item) => 
                        <div className={styles.question} key={item.key}>
                            {item.type === 'paragraph' &&
                                <p>{item.text}</p>
                            }

                            {item.type === 'textinput' &&
                                <TextField label={item.text} value={this.state.answers[item.key]} onChange={(e) => this.handleAnswerChange(item.key, e.target.value)} multiline={true} disabled={readOnly} />
                            }

                            {item.type === 'select' &&
                                <ChoiceGroup label={item.text} selectedKey={this.state.answers[item.key]} options={item.options} onChange={(e, value) => this.handleAnswerChange(item.key, value.key)} disabled={readOnly} />
                            }

                            {item.type === 'multiselect' &&
                            <>
                                <Label className={styles.multiselectLabel} disabled={readOnly}>{item.text}</Label>
                                {item.options.map( option =>
                                    <Checkbox key={option.key} className={styles.multiselectOption} label={option.text} checked={this.state.answers[item.key].indexOf(option.key) >= 0} onChange={() => this.handleAnswerChange(item.key, option.key)} disabled={readOnly} />
                                )}
                            </>
                            }
                        </div>
                    )}
                    </div>
                    {readOnly && 
                    <p><em>Die Befragung wurde am {new Date(this.state.item.filled).toLocaleDateString()} ausgefüllt.</em></p>
                    }
                    <div className={styles.footer}>
                        {!readOnly && <PrimaryButton text="Absenden" onClick={(e) => this.handleSaveClick(e)} />}
                        <DefaultButton text="Abbrechen" onClick={(e) => this.props.history.goBack()} />
                    </div>
                </form>
                </>
                }
                </div>
            </div>
        )
    }

    _selectItem(){
        const id = this.props.match.params.id;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                const fid = selectedItem[0].type;
                import(`./fragen/fragen-${fid}.js`).then(Fragen => {
                    const temp = {};
                    Fragen.default.questions.forEach((item) => {
                        switch (item.type) {
                            case 'textinput':
                            case 'select':
                                temp[item.key] = '';
                                break;

                            case 'multiselect':
                                temp[item.key] = [];
                                break;

                            default:
                                break;
                        }
                    });

                    _.merge(temp, selectedItem[0].answers);

                    this._survey = Fragen.default;

                    this.setState({ 
                        item: selectedItem[0],
                        answers: temp, 
                        questionaer: Fragen.default
                    });
                });
            }
        }
    }

    checkConditions(item){
        if(item.hidden === true){
            return false;
        } else {
            let showItem = true;
            let values = [];

            item.conditions?.forEach(condition => {
                const {type, operator, value} = {...condition};
                let key = null;

                // Feld wählen
                switch (type) {
                    case 'group':
                        values.push(this.checkConditions(condition));
                        break;

                    case 'usergroup':
                        key = this.props.user.usergroup;
                        break;

                    case 'answer':
                        key = this.state.answers[condition.key];
                        break;

                    default:
                        break;
                }

                // Bedingung prüfen
                switch (operator) {
                    case '=':
                        values.push(key === value);
                        break;

                    case '!=':
                        values.push(key !== value);
                        break;

                    case 'inArray':
                        values.push(value.indexOf(key) >= 0);
                        break;

                    case 'notInArray':
                        values.push(value.indexOf(key) === -1);
                        break;

                    default:
                        break;
                }
            })

            if(item.operator === 'or'){
                showItem = false;
                values.forEach(value => showItem = showItem || value);
            } else {
                values.forEach(value => showItem = showItem && value);
            }

            return showItem;
        }
    }

    prepareText(text){
        return text.replace('{$SurveyURL}', '/surveys/' + this.state.item.id);
    }

    handleAnswerChange(target, value){
        const copy = {...this.state.answers};

        if(Array.isArray(copy[target])){
            const index = copy[target].indexOf(value);
            if(index >= 0 ){
                copy[target].splice(index, 1);
            } else {
                copy[target].push(value);
            }
        } else {
            copy[target] = value;
        }

        this.setState({ answers: copy });
    }

    handleSaveClick(){
        let item = this.state.item;
        item.filled = new Date().toISOString();
        item.answers = this.state.answers;
        let message = '';

        this._survey?.actions?.forEach(action => {
            if(this.checkConditions(action)){
                switch (action.type) {
                    case 'create-ticket':
                        const ticket = {
                            customer: this.state.item.user,
                            subject: action.content.subject,
                            status: 'open',
                            history: [
                                {
                                    type: 'create-ticket', 
                                    employee: 1, 
                                    date: new Date().toISOString(), 
                                    content: 'Ticket erstellt.'
                                },{
                                    type: 'notice', 
                                    employee: 1, 
                                    date: new Date().toISOString(), 
                                    content: this.prepareText(action.content.text)
                                }
                            ],
                        };

                        this.props.onSave('tickets', ticket);
                        break;

                    case 'show-message':
                        message += action.content.text + ' ';
                        break;

                    default:
                        break;
                }
            }
        });

        this.props.onSave('surveys', item, {title: 'Vielen Dank für Ihre Teilnahme an der Befragung', body: message});
        this.props.history.goBack();
    }
}

export default Survey;