import React, {Component} from 'react';
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Fabric, loadTheme, initializeIcons, FontIcon, Dialog, DialogFooter, PrimaryButton, IconButton } from '@fluentui/react';

import NavWithRouter from './parts/NavWithRouter';
import LoginForm from './LoginForm';
import Dashboard from './Dashboard';
import Users from './Users';
import User from './User';
import UserOSZL from './User-oszl';
import Recipe from './Recipe';
import Recipes from './Recipes';
import Survey from './Survey';
import Surveys from './Surveys';
import Ticket from './Ticket';
import Tickets from './Tickets';
import Application from './Application';
import ApplicationData from './ApplicationData';
import Applications from './Applications';
import Registration from './Registration';
import RegistrationOSZL from './Registration-oszl';
import Statistics from './Statistics';
import Settings from './Settings';
import Page from './Page';
import Config from './Config';

import styles from './App.module.css';

const _ = {};
_.merge = require('lodash/merge');

class ListSorter {
  setColumn(column, sort){
    
    let feld = typeof(column.fieldName) !== 'undefined' ? column.fieldName : column.name;
    column.isSorted = sort?.field===feld;
    column.isSortedDescending = sort?.desc;
    return column;
  }
  sortItems(items, columns, field, dir){
    return items.sort((a,b) =>{
      let C = columns.filter(c => {
       // console.debug(c);
          if (typeof(c.fieldName)!=='undefined'){
              return c.fieldName === field;
          } else {
              return c.name === field;
          }
      });
      if (C.length > 0){
          let column = C[0], afield=null, bfield = null;
          if (typeof(column.onRender)==="function"){
              afield=column.onRender(a, column);
              bfield=column.onRender(b, column);
          } else {
              afield = a[ typeof(column.fieldName)!=='undefined' ? column.fieldName : column.name];
              bfield = b[ typeof(column.fieldName)!=='undefined' ? column.fieldName : column.name];
          }
          return (afield > bfield ? 1 : afield < bfield ? -1 : 0) * (dir === 'desc' ? -1 : 1)  ;
      }
      return 0;
    });
  }
  storeSort(view, sort){
    let sortings = {};
    if (typeof(sessionStorage['mdrSorting']) !== 'undefined' ){
      sortings = JSON.parse(sessionStorage['mdrSorting']);
    }
    sortings[view] = sort;
    sessionStorage['mdrSorting'] = JSON.stringify(sortings);
  }
  getSort(view, defaultSort){
    let sortings = {};
    if (typeof(sessionStorage['mdrSorting']) !== 'undefined' ){
      sortings = JSON.parse(sessionStorage['mdrSorting']);
    }
    if (typeof(sortings[view]) !=='undefined') {
      return sortings[view];
    } else {
      return defaultSort;
    }
  }
}
class App extends Component {
  constructor(props){
    super(props);

    this.config = Config;

    this.state = {
      config: null,
      loggedIn: null,
      filiale: null,
      token: null,
      message: null,
      users: [],
      recipes: [],
      tickets: [],
      surveys: [],
      settings: {},
      applications: [],
      showNav:true,
    };
    this.ListSorter = new ListSorter;
  }

  componentDidMount(){
    this._loadConfiguration();
    initializeIcons();

    if (typeof(localStorage.showNav)!=='undefined'){
      this.setState({showNav:localStorage.showNav==='true'})
    } else if (window.innerWidth<1024){
      this.setState({showNav:false})
    }
    
    this._loadConfigFile().then( result => {
      loadTheme(this.config.fluentTheme);

      const GET = queryString.parse(window.location.search);
      if (typeof(GET.token)==='string'){
        this.setState({token: GET.token},() => this.refreshLogin());
      }
    });

    if(localStorage.PeseoUser){
      const lastuser = JSON.parse(localStorage.PeseoUser);

      if ( lastuser.token.expires && ( (lastuser.token.expires*1000) > parseInt(Date.now()) ) ){
          this.setState({
              loggedIn: lastuser
            },() => this.loadDataInital());
      } else {
          localStorage.removeItem('PeseoUser');
      }
    } else if (localStorage.PeseoLocation) {
      const lastLocation = JSON.parse(localStorage.PeseoLocation);

      if ( lastLocation.token.expires && ( (lastLocation.token.expires*1000) > parseInt(Date.now()) ) ){
        this.setState({
            filiale: lastLocation
          });
      } else {
        localStorage.removeItem('PeseoLocation');
      }
    }

  }

  render(){
    const usergroup = this.state.loggedIn?.user.usergroup;
    const priv = ['system', 'admin', 'employee'];
    const ticketCount = this.state.tickets.filter( ticket => ticket.status === 'open').length;
    const ListSorterObj = this.ListSorter;
    return (
      <Fabric >
        <Router>
          <Switch>
            <Route path="/faq" render={routeProps => (
              <>
                {this.renderHeader(routeProps)}
                <Page content={{__html: this.state.config?.faq}} {...routeProps} />
              </>
              )} />
            <Route path="/datenschutz" render={routeProps => (
              <>
                {this.renderHeader(routeProps)}
                <Page content={{__html: this.state.config?.privacyReduced}} {...routeProps} />
              </>
              )} />
            <Route path="/datenschutz-intern" render={routeProps => (
              <>
                {this.renderHeader(routeProps)}
                <Page content={{__html: this.state.config?.privacy}} {...routeProps} />
              </>
              )} />
            <Route path="/impressum" render={routeProps => (
              <>
                {this.renderHeader(routeProps)}
                <Page content={{__html: this.state.config?.imprint}} {...routeProps} />
              </>
              )} />
            <Route>
              {(this.state.loggedIn !== null || this.state.filiale !== null) && this.state.showLogin !== true
              ? (
                <>
                  <Route render={routeProps => this.renderHeader(routeProps)} />
                  <main className={(priv.indexOf(usergroup) !== -1 ? styles.adminView : '')}>
                  
                  {priv.indexOf(usergroup) !== -1 &&
                  <div className={styles.leftbar + ' '+(this.state.showNav?styles.showNav: styles.hideNav)} >
                    <div className={styles.leftbarContent}  >
                      <NavWithRouter navLinks={this.config.navLinks} />
                      <div className={styles.leftbarDonwContent}  >
                        <IconButton iconProps={{iconName:this.state.showNav?'ChevronLeft':'ChevronRight'}} onClick={()=>{	
                            this.setState({showNav:!this.state.showNav},()=>{ localStorage.showNav = this.state.showNav})
                          }}  />
                      </div>
                    </div>
                  </div>
                  }
                  <Switch>
                    <Route path="/users/:id" render={routeProps => (
                      this.config.company === "Orthopädie-Schuh-Zentrum Leipzig GmbH" 
                      ? <UserOSZL config={this.config} user={this.state.loggedIn.user} items={this.state.users} onSave={(user) => this.saveItem('users', user)} {...routeProps} />
                      : <User config={this.config} user={this.state.loggedIn.user} items={this.state.users} onSave={(user) => this.saveItem('users', user)} {...routeProps} />
                      )} />
                    <Route path="/users/" render={routeProps => (
                      <Users config={this.config} user={this.state.loggedIn.user} items={this.state.users} onRefresh={() => this.loadItems('users')} ListSorter={ListSorterObj} {...routeProps} />
                      )} />
                    <Route path="/recipes/:id" render={routeProps => (
                      <Recipe config={this.config} user={this.state.loggedIn.user} settings={this.state.settings} items={this.state.recipes} onSave={(item) => this.saveItem('recipes', item)} users={this.state.users} {...routeProps} />
                      )} />
                    <Route path="/recipes" render={routeProps => (
                      <Recipes config={this.config} user={this.state.loggedIn.user} items={this.state.recipes} onRefresh={() => this.loadItems('recipes')} onSave={(item) => this.saveItem('recipes', item)} users={this.state.users} ListSorter={ListSorterObj} {...routeProps} />
                      )} />
                    <Route path="/surveys/:id" render={routeProps => (
                      <Survey config={this.config} user={this.state.loggedIn.user} items={this.state.surveys} onSave={(type, item, message) => this.saveItem(type, item, message)} {...routeProps} />
                      )} />
                    <Route path="/surveys" render={routeProps => (
                      <Surveys config={this.config} user={this.state.loggedIn.user} items={this.state.surveys} onRefresh={() => this.loadItems('surveys')} users={this.state.users} recipes={this.state.recipes} ListSorter={ListSorterObj} {...routeProps} />
                      )} />
                    <Route path="/tickets/:id" render={routeProps => (
                      <Ticket config={this.config} user={this.state.loggedIn.user} items={this.state.tickets} onSave={(item) => this.saveItem('tickets', item)} users={this.state.users} {...routeProps} />
                      )} />
                    <Route path="/tickets" render={routeProps => (
                      <Tickets config={this.config} user={this.state.loggedIn.user} items={this.state.tickets} onRefresh={() => this.loadItems('tickets')} users={this.state.users} ListSorter={ListSorterObj} {...routeProps} />
                      )} />
                    <Route path="/registration" render={routeProps => (
                      this.config.company === "Orthopädie-Schuh-Zentrum Leipzig GmbH" 
                      ? <RegistrationOSZL config={this.config} activeLocation={this.state.filiale} onSave={(item) => this.saveItem('users', item, {title: 'Aktion erfolgreich', body: 'Benutzer wurde gespeichert.'})} {...routeProps} />
                      : <Registration config={this.config} activeLocation={this.state.filiale} onSave={(item) => this.saveItem('users', item, {title: 'Aktion erfolgreich', body: 'Benutzer wurde gespeichert.'})} {...routeProps} />
                      )} />
                    <Route path="/statistics" render={routeProps => (
                      <Statistics config={this.config} user={this.state.loggedIn.user} recipes={this.state.recipes} users={this.state.users} {...routeProps} />
                      )} />
                    <Route path="/settings" render={routeProps => (
                      <Settings config={this.config} user={this.state.loggedIn.user} settings={this.state.settings} onSettingsChange={(name, value) => this._changeSettings(name, value)} onSave={settings => this._saveSettings(settings)} {...routeProps} />
                      )} />
                    <Route path="/applications/data/:id" render={routeProps => (
                      <ApplicationData config={this.config} user={this.state.loggedIn.user} items={this.state.applications} onSave={(item) => this.saveItem('applications', item)} users={this.state.users} {...routeProps} />
                      )} />
                    <Route path="/applications/:id" render={routeProps => (
                      <Application config={this.config} user={this.state.loggedIn.user} items={this.state.applications} onSave={(item) => this.saveItem('applications', item)} users={this.state.users} {...routeProps} />
                      )} />
                    <Route path="/applications" render={routeProps => (
                      <Applications config={this.config} user={this.state.loggedIn.user} items={this.state.applications} onRefresh={() => this.loadItems('applications')} users={this.state.users} {...routeProps} ListSorter={ListSorterObj} />
                      )} />
                    <Route path="/" render={routeProps => (
                      <Dashboard config={this.config} user={this.state.loggedIn?.user || 'filiale'} ticketCount={ticketCount} onLocationModeClick={location => this.activateLocationMode(location)} onActivateLogin={() => this.handleActivateLogin()} {...routeProps} />
                      )} />
                  </Switch>
                  {this.config.showFAQ === true && <Link className={styles.floatingButton} to="/faq"><FontIcon iconName="StatusCircleQuestionMark" className={styles.icon} /></Link> }

                  <Dialog hidden={this.state.message === null} onDismiss={() => this.setState({message: null})} title={this.state.message?.title}>
                    <p>{this.state.message?.body}</p>
                    <DialogFooter>
                      <PrimaryButton onClick={() => this.setState({message: null})} text="OK" />
                    </DialogFooter>
                  </Dialog>
                  </main>
                </>
              ) : (
                <LoginForm config={this.config} filiale={this.state.filiale !== null} onLogin={(email, password, name, date) => this.handleLogin(email, password, name, date)} />
              )}
            </Route>
          </Switch>
        </Router>
      </Fabric>
    );
  }

  renderHeader(routeProps){
    return(
      <header className={styles.header + ' app-header'}>
        <span className={styles.left}><img className={styles.logo} src="/data/logo.png" alt={this.config.company} /></span>
        {this.state.loggedIn || this.state.filiale ? <span className={styles.right} onClick={(e) => this.handleLogout(e, routeProps.history)}>Abmelden</span> : <span>&nbsp;</span>}
      </header>
    );
  }

  _loadConfiguration(){
    this._get(this.config.apiURL + '/config').then( result => {
      this.setState({config: result});
    }).catch( error => console.error(error));
  }

  _loadConfigFile(){
    return this._get('/data/config.json').then( projectConfig => {
      _.merge(this.config, projectConfig);

      if(projectConfig.applications === true){
        this.config.navLinks[0].links.push(
          {
            name: 'Arbeitsschutz',
            url: '/applications',
            icon: 'Work',
            key: '/applications'
          }
        )
      }
    }).catch( error => {
      console.error(error);
    });
  }

  loadDataInital(){
    this.loadItems('users');
    this.loadItems('recipes');
    this.loadItems('tickets');
    this.loadItems('surveys');
    this.loadItems('applications');
    this._loadSettings();
  }

  loadItems(type){
    this._get(this.config.apiURL + '/' + type).then( result => {
      const stateObject = {};
      stateObject[type] = result._embedded;
      this.setState(stateObject);
    }).catch( error => console.error(error));
  }

  saveItem(type, item, message = ''){
    if(typeof item.id !== 'undefined') {
      this._put(this.config.apiURL + '/' + type + '/' + item.id, item).then( result => {
        if(result.status === 'error'){
          message = {title: 'Aktion fehlgeschlagen', body: 'Bitte wenden Sie sich an den Support. Fehler: ' + result.message}
        } else {
          this.loadItems(type)
        }
      }).then( () => {
        if(message !== ''){
          this.setState({ message: message});
        }
      });
    } else {
      this._post(this.config.apiURL + '/' + type, item).then( result => {
        if(result.status === 'error'){
          message = {title: 'Aktion fehlgeschlagen', body: 'Bitte wenden Sie sich an den Support. Fehler: ' + result.message}
        } else {
          this.loadItems(type)
        }
      }).then( () => {
        if(message !== ''){
          this.setState({ message: message});
        }
      });
    }
  }

  _loadSettings(){
    this._get(this.config.apiURL + '/settings').then( result => {
      this.setState({settings: result});
    }).catch( error => console.error(error));
  }

  _changeSettings(name, value){
    let copy = {...this.state.settings};
    copy[name] = value;
    this.setState({settings: copy});
  }

  _saveSettings(settings){
    this._put(this.config.apiURL + '/settings', settings).then( result => {
      this._loadSettings();
    }).catch( error => console.error(error));
  }

  activateLocationMode(location){
    const copy = this.state.loggedIn;

    if(typeof location !== 'undefined'){
      copy.user.location = location;
    }
    localStorage.setItem('PeseoLocation', JSON.stringify(copy));
    localStorage.removeItem('PeseoUser');

    this.setState({
      filiale: copy,
      loggedIn: null
    })
  }

  handleActivateLogin(){
    this.setState({
      showLogin: true,
    })
  }

  _getAuth(){
    let value = '';

    if(this.state.token){
        value = 'Bearer ' + this.state.token;
    }

    if(this.state.filiale){
        value = 'Bearer ' + this.state.filiale.token.token;
    }

    if(this.state.loggedIn){
        value = 'Bearer ' + this.state.loggedIn.token.token;
    }

    return value;
  }

  _get(url) {
    return fetch(url, {
        cache: 'no-cache',
        headers: {
            'Authorization': this._getAuth()
        },
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
  }

  _post(url, data) {
    return fetch(url, {
        body: JSON.stringify(data),
        cache: 'no-cache',
        headers: {
            'content-type': 'application/json',
            'Authorization': this._getAuth()
        },
        method: 'POST',
        mode: 'cors'
    }).then(response => response.json())
  }

  _put(url, data) {
    return fetch(url, {
        body: JSON.stringify(data),
        cache: 'no-cache',
        headers: {
            'content-type': 'application/json',
            'Authorization': this._getAuth()
        },
        method: 'PUT',
        mode: 'cors'
    }).then(response => response.json())
  }

  handleLogin(email, password, name, date){
    const data = (name !== '' && date !== '') ? { name: name, date: date } : { email: email, password: password };

    this._post(this.config.apiURL + '/auth', data).then( (result) => {
      if (result.token){
        localStorage.setItem('PeseoUser', JSON.stringify(result));
        localStorage.removeItem('PeseoLocation');

        this.setState({
          loggedIn: result,
          showLogin: false,
        },() => this.loadDataInital());
      }
    }).catch( (error) => {
      console.error(error);
    })
  }

  refreshLogin(){
    this._get(this.config.apiURL + '/token').then( (result) => {
      if (result.token){
        localStorage.setItem('PeseoUser', JSON.stringify(result));

        const GET = queryString.parse(window.location.search);
        if (typeof(GET.token)==='string'){
          window.location.href=(window.location.href.replace('?token='+GET.token,''));
        }

        this.setState({
          loggedIn: result,
          showLogin: false,
        },() => this.loadDataInital());
      }
    }).catch( (error) => {
      console.error(error);
    })
  }

  handleBackLink(e, history){
    history.goBack();
  }

  handleLogout(e, history){
    localStorage.removeItem('PeseoUser');
    localStorage.removeItem('PeseoLocation');

    this.setState({
      filiale: null,
      loggedIn: null
    });

    history.push('/');
  }
}

export default App;
