import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown } from '@fluentui/react';
import { Bar, Pie } from 'react-chartjs-2';

import Helper from './Helper';
import styles from './Statistics.module.css';


class Statistics extends Component {
    constructor(props){
        super(props);

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: '', text: 'Alle Standorte'});

        this.state = {
            type: null,
            time: 3,
            location: '',
            weekOffset: -1
        }
    }

    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];

        const legendOptions = {
            position: 'bottom'
        };

        let barRecipes = this.props.recipes;

        if(this.state.location !== ''){
            barRecipes = barRecipes.filter(item => item.location === this.state.location);
        }

        let today = new Date();
        today = new Date(today.valueOf() + (this.state.weekOffset * 7 * 86400000));
        const offset = today.getDay() === 0 ? 6 : today.getDay() - 1;

        const barData ={
            datasets: [{
                data: [
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((0 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((1 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((2 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((3 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((4 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((5 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((6 - offset) * 86400000)))).length, 
                ],
                label: 'ausgeliefert',
                backgroundColor: '#159E6A',
                categoryPercentage: .5
            },{
                data: [
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((0 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((1 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((2 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((3 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((4 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((5 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((6 - offset) * 86400000)))).length, 
                ],
                label: 'angelegt',
                backgroundColor: '#2ACF82',
                categoryPercentage: .5
            }],
            labels: [
                'Mo, '+ (new Date(today.valueOf() + ((0 - offset) * 86400000))).toLocaleDateString(), 
                'Die, '+ (new Date(today.valueOf() + ((1 - offset) * 86400000))).toLocaleDateString(), 
                'Mi, '+ (new Date(today.valueOf() + ((2 - offset) * 86400000))).toLocaleDateString(), 
                'Do, '+ (new Date(today.valueOf() + ((3 - offset) * 86400000))).toLocaleDateString(), 
                'Fr, '+ (new Date(today.valueOf() + ((4 - offset) * 86400000))).toLocaleDateString(), 
                'Sa, '+ (new Date(today.valueOf() + ((5 - offset) * 86400000))).toLocaleDateString(), 
                'So, '+ (new Date(today.valueOf() + ((6 - offset) * 86400000))).toLocaleDateString()
            ]
        };

        const reworkQuota = [{
            name: 'Alle Standorte',
            values: [this.props.recipes.filter(item => item.rework.length === 0).length, this.props.recipes.filter(item => item.rework.length > 0).length],
        }];

        const regularCustomers = this.props.users.filter(user => user.regularCustomer || this.isRegularCustomer(user.id)).length;
        const regularQuota = [{
            name: 'Alle Standorte',
            values: [this.props.users.length - regularCustomers, regularCustomers],
        }];

        this.props.config.locationOptions.forEach( location => {
            const recipes = this.props.recipes.filter(recipe => recipe.location === location.key);
            if(recipes.length > 0 ){
                const withRework = recipes.filter(item => item.rework.length > 0 ).length;
                reworkQuota.push({
                    name: location.text,
                    values: [recipes.length - withRework, withRework]
                })
            }

            const users = this.props.users.filter(user => user.location === location.key);
            if(users.length > 0 ){
                const regularCustomers = users.filter(user => user.regularCustomer || this.isRegularCustomer(user.id)).length;
                regularQuota.push({
                    name: location.text,
                    values: [users.length - regularCustomers, regularCustomers]
                })
            }
        });

        return(
            <div className={styles.root}>
                {priv.indexOf(usergroup) !== -1
                ?   <>
                    <div className={styles.heading}>
                        <h1>Statistik</h1>
                    </div>

                    <h2>Umsatzübersicht nach Rezepten</h2>
                    <div>
                        <div className={styles.barNav}>
                            <button onClick={() => this.goBack()}>zurück</button>
                            <div className={styles.middle}>
                                <p>Kalenderwoche: {this.getNumberOfWeek(today)}</p>
                                <Dropdown
                                    options={this.locationOptions}
                                    className={styles.filterDropdown}
                                    selectedKey={this.state.location}
                                    onChange={ (e, item) => this.setState({location: item.key})}
                                />
                            </div>
                            <button onClick={() => this.goForward()}>weiter</button>
                        </div>
                        <Bar data={barData} legend={legendOptions} />
                    </div>
                    <p>&nbsp;</p>

                    <h2>Nacharbeitsquote</h2>
                    <div className={styles.flexi}>
                        {reworkQuota.map(item => {
                            return(
                            <div key={item.name}>
                                <h3>{item.name}</h3>
                                <Pie data={{datasets: [{data: item.values, backgroundColor: ['#159E6A','#2ACF82',]}], labels: ['fehlerfrei','mit Nacharbeit']}} legend={legendOptions} />
                            </div>)
                        })}
                    </div>
                    <p>&nbsp;</p>

                    <h2>Stammkundenquote</h2>
                    <div className={styles.flexi}>
                        {regularQuota.map(item => {
                            return(
                            <div key={item.name}>
                                <h3>{item.name}</h3>
                                <Pie data={{datasets: [{data: item.values, backgroundColor: ['#159E6A','#2ACF82',]}], labels: ['Neukunden','Stammkunden']}} legend={legendOptions} />
                            </div>)
                        })}
                    </div>
                    <p>&nbsp;</p>

{/*
                    <h2>Mitarbeiterwettbewerb - Wer hat die meißten Tickets bearbeitet?</h2>
                    <div>
                        {items.map( item => {
                            return(

                            <div className={styles.position} key={item.index}>
                                <span className={styles.name}>{item.name}</span>
                                <div className={styles.progress}>
                                    <Progress value={item.value} />
                                </div>
                            </div>
                                )
                        })}
                    </div>
                    <p>&nbsp;</p>
                    */}
                    </>
                :   <Redirect push to="/" />
                }
            </div>
        )
    }

    goBack(){
        const offset = this.state.weekOffset;
        this.setState({weekOffset: offset - 1});
    }

    goForward(){
        const offset = this.state.weekOffset;
        this.setState({weekOffset: offset + 1});
    }

    getNumberOfWeek(today){
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    isRegularCustomer(customer){
        const recipes = this.props.recipes.filter(recipe => recipe.user === customer);
        let regular = false;

        if(recipes.length > 1){
            const referenz = recipes[0].orderDate;
            recipes.forEach(recipe => {
                regular = regular || recipe.orderDate !== referenz;
            })
        }

        return regular;
    }
}

export default Statistics;