import React, { Component } from 'react';
import { Nav } from '@fluentui/react';
import { withRouter } from 'react-router-dom';

class NavWithRouter extends Component {
    render() {
        const { history, navLinks } = this.props;

        return (
            <div className="PortalNav">
                <Nav
                    groups={navLinks}
                    onLinkClick={(event, element) => {
                        event.preventDefault();
                        history.push(element.url);
                    }}
                />
            </div>
        )
    }
}

export default withRouter(NavWithRouter);