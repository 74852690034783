import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontIcon, PrimaryButton, SearchBox, Pivot, PivotItem, DetailsList, Selection, SelectionMode, Dropdown } from '@fluentui/react';

import styles from './Applications.module.css';


class Applications extends Component {
    constructor(props){
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                if(this._selection.getSelection().length > 0){
                    this.props.history.push('/applications/' + this._selection.getSelection()[0].id);
                }
            }
        })

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: 0, text: 'Alle Filialen'});

        this.employeeOptions = [{key: 0, text: 'Alle Mitarbeiter'}];

        this.state = {
            search: '',
            pivot: 'all',
            location: 0,
            employee: this.props.user.id,
            sort:{field:'id', desc:false}
        }
    }
    
    onColumnHeaderClick=(e, column)=>{
        let feld = typeof(column.fieldName) !== 'undefined' ? column.fieldName : column.name;
        let desc = this.state.sort.field === feld ? !this.state.sort.desc : false;
        this.setState({sort:{field:feld, desc:desc}}, ()=>this.props.ListSorter.storeSort(this.constructor.name, this.state.sort));
    }

    componentDidMount(){
        this.props.onRefresh();
        this.refreshInterval = window.setInterval(() => this.props.onRefresh(), 5000);
        if (typeof(this.props.ListSorter.getSort)==='function'){
            this.setState({sort: this.props.ListSorter.getSort(this.constructor.name,this.state.sort)})
        }

        this.employeeOptions = [{key: 0, text: 'Alle Mitarbeiter'}];
        this.props.users.forEach(item => {
            if(item.usergroup !== 'users'){
                this.employeeOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
            }
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.users !== prevProps.users){
            this.employeeOptions = [{key: '', text: 'Alle Mitarbeiter'}];
            this.props.users.forEach(item => {
                if(item.usergroup !== 'users'){
                    this.employeeOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];

        let items = this.props.items;

        if(this.state.location !== 0){
            items = items.filter(item => item.location === this.state.location);
        }
/*
        if(this.state.employee !== 0){
            items = items.filter(item => item.history[item.history.length-1].employee === this.state.employee || item.history[item.history.length-1].employee === 1);
        }
*/
        const countAll = items.length;
        const countOpen = items.filter(item => item.status === 'open').length;
        const countGranted = items.filter(item => item.status === 'granted').length;
        const countRejected = items.filter(item => item.status === 'rejected').length;

        if(this.state.pivot !== 'all'){
            items = items.filter(item => item.status === this.state.pivot);
        }

        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');
            items = items.filter( item => {
                let found = true;
                parts.forEach(part => found &= (item.subject.toLowerCase().indexOf(part) >= 0));
                return found;
            });
        }

        const columns = [
            {
                key: 'column0',
                name: 'ID',
                fieldName: 'id',
                isResizable: true,
                isPadded: true,
                minWidth: 10,
                maxWidth: 20,
            },
            {
                key: 'column1',
                name: 'Kunde',
                fieldName: 'customer',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (this.props.users){
                        const copy = [...this.props.users];
                        const result = copy.filter(user => user.id === item.customer);
                        if(result.length > 0){
                            if(item.data.name === undefined && item.data.forename === undefined || item.data.name === undefined  || item.data.forename === undefined) {
                                return result[0].lastname + ', ' + result[0].firstname + ' (Sachbearbeiter)';
                            } else {
                                return item.data.name + ', ' + item.data.forename;
                            }
                        }
                    }
                    return item.customer;
                },
            },
            {
                key: 'column2',
                name: 'Datensatz',
                fieldName: 'data',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (Object.keys(item.data).length > 0){
                        return 'Komplett'
                    } else {
                        return 'unvollständig'
                    }
                },
            },
            {
                key: 'column3',
                name: 'E-Mail',
                fieldName: 'email',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if (Object.keys(item.data).length > 0){
                        return 'Komplett'
                    } else {
                        return 'unvollständig'
                    }
                },
            },
            {
                key: 'column4',
                name: 'Termin',
                fieldName: 'forms',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if(item.forms === "pending") {
                        return 'steht aus';
                    } else if(item.forms === "complete") {
                        return 'Komplett';
                    }
                },
            },
            {
                key: 'column5',
                name: 'Arzt',
                fieldName: 'doctor',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if(item.doctor === "pending") {
                        return 'steht aus';
                    } else if(item.doctor === "complete") {
                        return 'Komplett';
                    }
                },
            },
            {
                key: 'column6',
                name: 'Bewilligung',
                fieldName: 'status',
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    if(item.status === "pending") {
                        return 'steht aus';
                    } else if(item.status === "open") {
                        return 'offen';
                    }  else if(item.status === "granted") {
                        return 'gewährt';
                    }
                },
            },
        ].map(column => {
            return this.props.ListSorter.setColumn(column, this.state.sort)
         });
         items = this.props.ListSorter.sortItems(items, columns,this.state.sort.field , this.state.sort.desc?'desc':'asc');;

        return(
            <div className={styles.root}>
                {priv.indexOf(usergroup) !== -1
                ?   <>
                    <div className={styles.heading}>
                        <PrimaryButton className={styles.left} text="Neuer Antrag" onClick={(e) => this.props.history.push('/applications/new')} />
                        <Dropdown
                            options={this.employeeOptions}
                            selectedKey={this.state.employee}
                            onChange={(e, item) => this.setState({employee: item.key})}
                            className={styles.filterDropdown}
                        />
                        <Dropdown
                            options={this.locationOptions}
                            selectedKey={this.state.location}
                            onChange={(e, item) => this.setState({location: item.key})}
                            className={styles.filterDropdown}
                        />
                        <SearchBox className={styles.search} placeholder="Suche" value={this.state.search} onChange={e => {if(e){this.setState({search: e.target.value})}}} onClear={() => this.setState({search: ''})} />
                    </div>

                    <div className={styles.content}>
                        <h1>Arbeitsschutz-Anträge</h1>

                        <Pivot onLinkClick={(item) => this.setState({pivot: item.props.itemKey})}>
                            <PivotItem itemKey="all" headerText="alle Anträge" itemCount={countAll}></PivotItem>
                            <PivotItem itemKey="open" headerText="offene Anträge" itemCount={countOpen}></PivotItem>
                            <PivotItem itemKey="granted" headerText="akzeptierte Anträge" itemCount={countGranted}></PivotItem>
                            <PivotItem itemKey="rejected" headerText="abgelehnte Anträge" itemCount={countRejected}></PivotItem>
                        </Pivot>

                        <DetailsList
                            items={items}
                            compact={true}
                            columns={columns}
                            layoutMode={1}
                            selectionMode={SelectionMode.single}
                            selection={this._selection}
                            onColumnHeaderClick={this.onColumnHeaderClick}
                        />
                    </div>
                    </>
                :   <Redirect push to="/" />
                }
            </div>
        )
    }
}

export default Applications;